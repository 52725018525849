import { Component, Host, Prop, Element, h } from '@stencil/core';
import { nanoid } from 'nanoid';

@Component({
  tag: 'x-table-schema',
})
export class XTableSchema {
  @Element() el: HTMLElement;

  @Prop() col: boolean = false;

  private parseAttrs = node => {
    const booleanAttrs = ['col', 'empty', 'strict'];

    return Array.prototype.reduce.call(
      node.attributes,
      (result, attr) => {
        const name = attr.nodeName;
        let value = attr.nodeValue;

        if (booleanAttrs.indexOf(name) !== -1 && value === '') {
          value = true;
        }

        result[name] = value;

        return result;
      },
      {},
    );
  };

  componentDidLoad() {
    if (!this.col) {
      const style = document.createElement('style');
      const id = `css_${nanoid(5)}`;

      let rules = '';

      (this.el.parentNode as any).setAttribute('id', id);
      this.el.parentNode.appendChild(style);

      Array.prototype.forEach.call(this.el.children, (col, index) => {
        const { width, align, valign, empty, strict } = this.parseAttrs(col);

        rules += `#${id} table th:nth-child(${index + 1}),#${id} table td:nth-child(${index + 1}) {`;

        if (width) {
          if (strict) {
            rules += `min-width:${width};`;
          } else {
            rules += `width:${width};`;
          }
        }
        if (align) rules += `text-align:${align};`;
        if (valign) rules += `vertical-align:${valign};`;
        if (empty) rules += 'font-size:0;line-height:0;';

        rules += '}';

        if (valign) {
          rules += `#${id} table td:nth-child(${index + 1}) {`;
          rules += `vertical-align:${valign};`;
          rules += '}';
        }
      });

      style.innerHTML = rules;
    }
  }

  render() {
    return <Host></Host>;
  }
}
